/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: jua;
    src: url(./assets/fonts/Jua/Jua-Regular.ttf);
}

@font-face {
    font-family: proxima;
    src: url(../src/assets/fonts/ProximaNovaRegular.ttf);
}
@font-face {
    font-family: proxima-bold;
    src: url(../src/assets/fonts/ProximaNovaSemibold.ttf);
}

/* my portfolio font */
@font-face { 
    font-family: seymourOne;
    src: url(../src/assets/fonts/SeymourOne-Regular.ttf);
}

@media screen and (prefers-reduced-motion: reduce) {  
    * {
      /* Very short durations means JavaScript that relies on events still works */
      animation-duration: 0.001ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.001ms !important;
    }
  }

.overflow-hidden {
    overflow: -moz-scrollbars-none;
}

.black-font{
    color: #373737;
}

.professional-blue-font{
    color: #3E5879;
}

/* Hide scrollbar for IE, Edge */
.overflow-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for WebKit browsers (Chrome, Safari) */
.overflow-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

:root{
    --font-jua:jua;
    --button-color : #FFA800;
    --white-color:#ffffff;
    --font-proxima:proxima;
    --font-proxima-bold:proxima-bold;
    --seymourOne:seymourOne;
    --border-color-portfolio : #FEDE65;
    --admin-title-color : #455989;
}

#componentHeader {
    width: 100%;
    font-family: var(--font-proxima-bold);
}
  
  #componentTitle {
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;
    font-size: 1.4rem;
    box-shadow: 0px 5px 15px 0px #3368e840;
    margin-right: 0.5rem;
  }
  
  #teacherName {
    text-align: bottom;
  }


.nameSection{
    background: #afd6fa;
    box-shadow: inset 0px 1px 5px 2px rgba(0, 0, 0, 0.2);
    width: 85vw;
    border-radius: 1rem;
    height: 3rem;
    font-family: var(--font-proxima-bold);
    font-size: 1.1rem;
}
.studentName{
    width: 100vw;
    border-radius: 0rem 0rem 1rem 1rem
}

.backButton{
    width: 8rem;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 0.5rem;
    font-family: var(--font-proxima-bold);
    border-radius: 4rem;
    border: none;
    font-weight: 400;
    margin-bottom: 4.5rem;
    box-shadow: 0px 2px 4px rgba(75, 73, 73, 0.5);
    background-color: var(--button-color); 
    color: #ffffff; 
    font-size: 1.2em;
    z-index: 5;
    text-transform: uppercase;
}

/* Display Flex */
.d-flex {
    display: flex;
}

/* Flex Direction */
.flex-row {
    flex-direction: row; /* Default: left to right */
}
.flex-row-rev {
    flex-direction: row-reverse; /* Right to left */
}
.flex-col {
    flex-direction: column; /* Top to bottom */
}
.flex-col-rev {
    flex-direction: column-reverse; /* Bottom to top */
}

/* Justify Content (Horizontal Alignment) */
.just-center {
    justify-content: center; /* Center content horizontally */
}
.just-start {
    justify-content: flex-start; /* Align items to the start (left) */
}
.just-end {
    justify-content: flex-end; /* Align items to the end (right) */
}
.just-around {
    justify-content: space-around; /* Equal space around each item */
}
.just-between {
    justify-content: space-between; /* Equal space between items */
}
.just-even {
    justify-content: space-evenly; /* Equal space between and around items */
}

/* Align Items (Vertical Alignment) */
.align-center {
    align-items: center; /* Center items vertically */
}
.align-start {
    align-items: flex-start; /* Align items to the top */
}
.align-end {
    align-items: flex-end; /* Align items to the bottom */
}
.align-baseline {
    align-items: baseline; /* Align items by their baseline */
}
.align-stretch {
    align-items: stretch; /* Stretch items to fill the container */
}

/* Flex Wrap */
.flex-wrap {
    flex-wrap: wrap; /* Wrap items to the next line */
}
.flex-nowrap {
    flex-wrap: nowrap; /* Prevent wrapping, single line */
}
.flex-wrap-rev {
    flex-wrap: wrap-reverse; /* Wrap items in reverse order */
}

/* Align Content (When wrapping items) */
.align-cont-start {
    align-content: flex-start; /* Align wrapped rows to the top */
}
.align-cont-center {
    align-content: center; /* Center wrapped rows vertically */
}
.align-cont-end {
    align-content: flex-end; /* Align wrapped rows to the bottom */
}
.align-cont-around {
    align-content: space-around; /* Equal space around each row */
}
.align-cont-between {
    align-content: space-between; /* Equal space between rows */
}
.align-cont-even {
    align-content: space-evenly; /* Equal space between and around rows */
}

/** Center the content */
.flex-cent{
   display: flex;
   align-items: center;
   justify-content: center;
}

/** Space around */
.flex-space-around{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.textBold{
    font-family: var(--font-proxima-bold);
}

.font-bold{
    font-weight: bold;
}